import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Decentralized (dApp) development',
        description: 'Crafting decentralized applications (dApps) using blockchain technology for transparent, secure, and peer-to-peer interactions in a trustless environment.'
    },
    {
        icon: <FiLayers />,
        title: 'Non-fungible token (NFT) development',
        description: 'Innovating with non-fungible token (NFT) development, transforming digital assets into unique, verifiable tokens for authenticity, ownership, and decentralized creativity.'
    },
    {
        icon: <FiUsers />,
        title: 'Custom blockchain app development',
        description: 'Tailoring blockchain applications, seamlessly merging innovation and functionality for clients seeking customized solutions in the decentralized digital landscape.'
    },
    {
        icon: <FiMonitor />,
        title: 'Smart contracts development',
        description: 'Engineer intelligent and secure smart contracts, automating and executing agreements on blockchain with precision, transparency, and decentralized trust for efficiency.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'Leetlabs offers comprehensive Web3 development services, including blockchain integration, smart contract development, decentralized applications, and tailored solutions for businesses.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/#">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
