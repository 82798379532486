import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About Us',
        description = 'At Leetlabs, we are at the forefront of Web3 development, dedicated to shaping the future of decentralized technologies. With a passion for innovation, we embark on a mission to redefine digital landscapes. Our team of experts thrives on pushing boundaries, crafting cutting-edge solutions that empower businesses in the decentralized era. Committed to excellence, Leetlabs seamlessly integrates blockchain, smart contracts, and emerging technologies to unlock limitless possibilities. As architects of the decentralized web, we believe in driving meaningful change, fostering trust, and empowering individuals and enterprises to thrive in a new era of secure, transparent, and decentralized digital ecosystems';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-us-1.svg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    {/* <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;